import React, {useEffect, useState} from "react";
import {Table, Row, Col, Button} from "react-bootstrap";

import ClientAbstractHeader from "./ClientAbstractHeader";
import {AbstractTableHeaders, AbTable} from "../../Abstract/ClientAbstractTable";
import {useParams} from "react-router";
import PacerMenu from "../../Menu";
import {Link} from "react-router-dom";
import {getClientReport} from "../../../utils/queries/QueriesForUsersAndClients";

const AbstractTable = () => {
    const params = useParams()
    const [report, setReport] = useState({});


    useEffect(() => {
      getClientReport(params.id, setReport)
        if (report !== undefined) {
        }
    }, []);

    return (<div className={"m-5"}>
            <PacerMenu/>
        <Row>
            <Col>
                <Button
                    className={"m-2"}
                    as={Link}
                    to={`/clienthome`}
                >Back</Button>

            </Col>
            <Col></Col>
            <Col>
            </Col>
        </Row>
        {
            report.client !== undefined ?
        <ClientAbstractHeader
            client={report.client}
            headerDetails={report.header}
            documentList={report.documents}
        />
                : <></>
        }
        <Table striped bordered hover>
            {AbstractTableHeaders}
            <tbody>
            <AbTable
                documentList={report.documents}
            />
            </tbody>
        </Table>
    </div>
    )
}

export default AbstractTable;