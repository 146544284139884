import {sheetStyles} from "./sheetStyles";

export const getAlphaFromIndex = (index: number) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split('');
    return alphabet[index]
}

export const getIndexFromAlpha = (alpha: any,) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split('');
    return alphabet.indexOf(alpha.toUpperCase())
}

export const setCells = (sheet: any, row: any, cellsArr: any, ) => {

 let cellObj: any;
    let values = new Array();
    let col : any;
    let value : any;
    cellsArr.map((cell : any) => {
        col = Object.keys(cell)[0]
        value = Object.values(cell)[0]
        values[getIndexFromAlpha(col)] = value;
        // console.log(`${column.toUpperCase()}:${row}`)
        cellObj = {...cellObj, [col]: cell.style}
    });
        sheet.getRow(row).values = values
        setCellStyles(sheet, [row], cellObj)
}
export const setValues = (sheet: any, row: any, valueObj: any) => {
    let values = new Array();
    Object.entries(valueObj).map((val : any) => {
     values[getIndexFromAlpha(val[0])] = val[1];
    })
    sheet.getRow(row).values = values
}

export const setCellStyles = (sheet: any, rowArr: any,  colStyleObj: any) => {
    for (let i = 0; i < rowArr.length; i++) {
        Object.entries(colStyleObj).map((style: any) => {

                sheet.getCell(`${(style[0]).toUpperCase()}:${rowArr[i]}`).style = style[1];
                // sheet.getCell("A:8").style = sheetStyles.textStyle;
        })
        // }
    }
}

export const setStyles = (sheet: any, rowArr: any,  colStyleObj: any) => {
    for (let i = 0; i < rowArr.length; i++ ) {
        Object.entries(colStyleObj).map((style : any) => {

        sheet.getCell(`${(style[0]).toString().toUpperCase()}:${rowArr[i]}`).style = style[1];
        })
    }
}
export const setMergesByRow = (sheet: any, rowArr: any,  colPairArr: any) => {
    rowArr.forEach((row: number) =>  {
        colPairArr.forEach((col: string) => {
            const colSplit = convertColToUpperCase(col)
            sheet.mergeCells(`${colSplit[0]}${row}:${colSplit[1]}${row}`)
        })
    })
}

export const setMergesByArea = (sheet: any, merges: any) => {
    merges.forEach((merge: any) =>  {
        const mergeSplit = merge.split(" ")
        const col1 = convertColToUpperCase(mergeSplit[0])
        const col2 = convertColToUpperCase(mergeSplit[1])
            sheet.mergeCells(`${col1}:${col2}`)
        })
}


function convertColToUpperCase (str: any) {
    if (!str) {
        return '';
    }
    const strArr = str.split('').map((char: any) => {
        if (typeof(char) !== "number" )
        return char.toUpperCase()
    });
    return strArr.join('');
}
