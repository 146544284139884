export const sheetStyles = {
    textStyle: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
        },
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        },
    },
        textStyleAlignLeft: {
            alignment: {vertical: 'middle', horizontal: 'left', wrapText: true},
            font: {
                name: "Arial",
                family: 4,
                size: 10,
            },
            border: {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            },
        },
    boldAlignLeft: {
        alignment: {vertical: 'middle', horizontal: 'left', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true
        },
        border: {
            // top: {style: 'thin'},
            left: {style: 'thin'},
            // bottom: {style: 'thin'},
            right: {style: 'thin'}
        },
    },

            alignLeftLink: {
                alignment: {vertical: 'middle', horizontal: 'left', wrapText: true},
                font: {
                    name: "Arial",
                    family: 4,
                    size: 10,
                    color: {'argb': 'FF0000EE'},
                    underline: "single"
                },
                border: {
                    // top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    // right: {style: 'thin'}
                },
    },



    textStyleBorderless: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
        },

    },

    hyperLinkStyle: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            color: {'argb': 'FF0000EE'},
            underline: "single"
        },
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        },
    },

    headerStyle: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        },
    },

   headerStyleYellowBorderLess: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffF3F2AB'}
        }
    },
    headerStyleYellowTopBorder: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffF3F2AB'}
        },
        border: {
            top: {style: 'thin'},
        },
    },
    headerStyleYellowBottomBorder: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffF3F2AB'}
        },
        border: {
            bottom: {style: 'thin'},
        },
    },
    headerStyleYellowSideBorders: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffF3F2AB'}
        },
        border: {
            left: {style: 'thin'},
            right: {style: 'thin'}
        },
    },
    headerStyleYellowSideAndTopBorders: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffF3F2AB'}
        },
        border: {
            left: {style: 'thin'},
            right: {style: 'thin'},
            top: {style: 'thin'},
        },
    },
    headerStyleYellowSideAndBottomBorders: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffF3F2AB'}
        },
        border: {
            left: {style: 'thin'},
            right: {style: 'thin'},
            bottom: {style: 'thin'},
        },
    },
    headerStyleGreyBorderLess: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        }
    },
    headerStyleGreyTopBorder: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        },
        border: {
            top: {style: 'thin'},
        },
    },
    headerStyleGreyBottomBorder: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        },
        border: {
            bottom: {style: 'thin'},
        },
    },
    headerStyleGreySideBorders: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        },
        border: {
            left: {style: 'thin'},
            right: {style: 'thin'},
        },
    },
    headerStyleGreyTopAndSideBorders: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        },
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            right: {style: 'thin'},
        },
    },
    headerStyleGreyBottomAndSideBorders: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        },
        border: {
            bottom: {style: 'thin'},
            left: {style: 'thin'},
            right: {style: 'thin'},
        },
    },
    alignLeftYellowBorderless: {
        alignment: {vertical: 'middle', horizontal: 'left', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
           bold: true
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffF3F2AB'}
        }
    },

    headerStyleGrey: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
        },
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        }
    },
    unverified: {
        alignment: {vertical: 'middle', horizontal: 'center', wrapText: true},
        font: {
            name: "Arial",
            family: 4,
            size: 10,
        },
        border: {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        },
        fill : {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ffB3B7BF'}
        }
    },



}
