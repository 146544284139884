import {Form} from "react-bootstrap";
import React, {useEffect} from "react";
import {queryForUserList} from "../../../../utils/queries/QueriesForUsersAndClients";


const SelectUser = (props) => {
    useEffect(() => {
        if (props.clientId !== undefined &&  props.setUserList !== undefined)
        queryForUserList(props.setUserList, props.clientId)
    }, [props.clientId]);
    return (
        <>
            <Form >
                <Form.Select
                    name={"user"}
                    onChange={props.onChange}
                >
                    { props.userList !== undefined?  props.userList.map((user, index) => {
                        return (
                            <option key={index} value={user.id}>{user.firstName + " " + user.lastName}</option>)
                    })
                    :
                    <></>}

                </Form.Select>
            </Form>


        </>
    )
}


export default SelectUser;