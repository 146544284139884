import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {AddUserToReportModal} from "../../pages/Admin/AddUserToReportModal";
import {Link} from "react-router-dom";
import {exportZipFile} from "../../features/Excel/exports/exportWithZip";
import {BiTrash} from "react-icons/bi";
import {addEditHandleDelete} from "../../utils/functions";
import {getClientReports} from "../../utils/queries/QueriesForUsersAndClients";
import {headerDetailsSignal} from "../../store/signalsStore";
export const ReportTableHeaders =
    <thead>
    <tr>
        <th>Reports</th>
        <th>Project</th>
        {/*<th>Expiration Date</th>*/}
        <th>Users with Access</th>
    </tr>
    </thead>
;
export const ReportTable = (props) => (
    props.reports.map((report, index) => {
    if (report.header.project === "no results found") {
        return (<tr key={index}>
            <td>No reports found</td>
        </tr>)
    }
        if (report.header.project !== "Select Report" )
            return (<tr key={index}>
                <td>
                    <Row>
                        <Col>
                            <Button
                                className={"m-2"}
                                as={Link}
                                to={`/adminreport/${report.id}`}
                            >View</Button>
                            <Button onClick={() => {
                                props.setReport(report)
                                props.handleShow()
                            }}
                            >Add User</Button>
                            <Button className="m-3" variant="secondary"
                                    onClick={() => {
                                        props.setLoading({message: "Gathering Data", percentage: 5})
                                        exportZipFile({
                                            header: [report.header],
                                            list: report.documents,
                                            files: report.abstractFiles,
                                            setLoading: props.setLoading,
                                            reportTitle: report.reportTitle
                                        })
                                    }}>Download
                                Abstract Package</Button>
                            <BiTrash
                                onClick={(e) => {
                                    addEditHandleDelete("ClientReports", report.id, props.handleClose)
                                    getClientReports(report.client, props.setClientReportList)
                                }}/>
                        </Col>
                        {/*<Col>*/}
                        {/*</Col>*/}
                    </Row>

                </td>
                <td>{report.header.project}
                </td>
                {/*<td>*/}
                {/*    10 days remaining*/}
                {/*</td>*/}
                <td>
                    {report.users !== undefined ?
                        report.users.map((user, index) => {
                            return (
                                <p key={index}>{user}</p>
                            )
                        })
                        : <></>}
                </td>
                <AddUserToReportModal show={props.show} handleClose={props.handleClose} report={props.report}/>
            </tr>)
        else return <React.Fragment key={index}></React.Fragment>
    })
);

export const AbstractReportTableHeaders =
    <thead>
    <tr>
        <th>Reports</th>
        <th>Project</th>
    </tr>
    </thead>
;
export const AbstractReportTable = (props) => (
    props.reports.map((report, index) => {
        if (report.header.project === "no results found") {
            return (<tr key={index}>
                <td>No reports found</td>
            </tr>)
        }

        if (report.header.project !== "Select Report")

            return (
                <tr key={index}>
                <td>
                    <Row>
                        <Col>
                            <Button
                                className={"m-2"}
                                onClick={() => {
                                    headerDetailsSignal.value = report.header
                                    props.setAbstractFiles(report.abstractFiles)
                                    props.setDocumentList(report.documents)
                                }}
                            >Load</Button>
                        </Col>

                    </Row>
                </td>
                <td>
                    <Row>
                        <Col>
                        {report.header.project}
                        </Col>
                    <Col>
                        <BiTrash
                            onClick={(e) => {
                                addEditHandleDelete("ClientReports", report.id, props.handleClose)
                                getClientReports(report.client, props.setClientReportList)
                            }}/>
                    </Col>
                    </Row>
                </td>
            </tr>
            )
        // else return (
        //         <tr key={index}>
        //             <td>
        //                 <Row>
        //                     <Col>
        //
        //                     </Col>
        //
        //                 </Row>
        //             </td>
        //             <td>
        //                 <Row>
        //                     <Col>
        //                         {report.header.project}
        //                     </Col>
        //                     <Col>
        //                         <BiTrash
        //                             onClick={(e) => {
        //                                 addEditHandleDelete("ClientReports", report.id, props.handleClose)
        //                                 getClientReports(report.client, props.setClientReportList)
        //                             }}/>
        //                     </Col>
        //                 </Row>
        //             </td>
        //         </tr>
        //     )
    })
);