import React, {useEffect} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import {exportZipFile} from "../../../features/Excel/exports/exportWithZip";
import {AddUserToReportModal} from "../../Admin/AddUserToReportModal";

export const ClientReportTableHeaders = (client) => {
    return (
        <thead>
        <tr>
            <th>Reports for {client}</th>
            <th>Project</th>
            <th>Project Link Expiration Date</th>
            <th>Users with Access</th>
        </tr>
        </thead>
        )

}
;
export const ClientReportTable = (props) => (
    props.reports.map((report, index) => {
        if (report.header.project !== "Select Report")
            return (
                <tr key={index}>
                    <td>
                        <Row>
                            <Col>
                                <Button
                                    className={"m-2"}
                                    as={Link}
                                    to={`/report/${report.id}`}
                                >View</Button>
                                <Button onClick={() => {
                                    props.setReport(report)
                                    props.handleShow()
                                }}
                                >Add User</Button>
                                <Button className="m-3" variant="secondary"
                                        onClick={() => {
                                            props.setLoading({message: "Gathering Data", percentage: 5})
                                            exportZipFile({
                                                header: [report.header],
                                                list: report.documents,
                                                files: report.abstractFiles,
                                                setLoading: props.setLoading} )
                                        }}>Download
                                    Abstract Package</Button>
                            </Col>
                        </Row>

                    </td>
                    <td>{report.header.project}
                    </td>
                    <td>
                        10 days remaining
                    </td>
                    <td>
                        {report.users !== undefined ?
                            report.users.map((user, index) => {
                            return (
                            <p key={index}>{user}</p>
                                )
                        })
                            : <></>}
                    </td>
                    <AddUserToReportModal show={props.show} handleClose={props.handleClose} report={props.report}/>
                </tr>)
        else return <React.Fragment key={index}></React.Fragment>
    })


);