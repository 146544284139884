import React from 'react';
import {Card, Button, ListGroup, Row, Col} from "react-bootstrap"
import {exportFile} from "../../features/Excel/exports/exportWithLinks";

const AdminViewClientAbstractHeader =(props) => {
    let title = "Runsheet for Abstract of Title For"
    if (props.reportTitle !== undefined) {title = props.reportTitle}
    const openFile = (downloadURL) => {
        window.open(downloadURL, '_blank');
    }
    return (
            <Card >
                <Card.Header> {title} {props.client} - {props.headerDetails.project}</Card.Header>
                <Card.Body>
                    <Card.Title>Pertaining to:</Card.Title>
                    <ListGroup >
                        <ListGroup.Item>Tract 1: {props.headerDetails.townshipAndRange}</ListGroup.Item>
                        <ListGroup.Item>Section {props.headerDetails.section}</ListGroup.Item>
                        <ListGroup.Item>County/State: {props.headerDetails.county} </ListGroup.Item>
                        <ListGroup.Item>Containing approximately {props.headerDetails.acreage} acres, more or less
                        </ListGroup.Item>
                        <ListGroup.Item> From: {props.headerDetails.date}</ListGroup.Item>
                        <ListGroup.Item> Last Page: {props.headerDetails.lastPage}</ListGroup.Item>
                    </ListGroup>
                    {props.abstractFiles[0] === undefined ? <></> :
                        <>
                            <Card.Title style={{marginTop: "10px"}}>Additional Files:</Card.Title>
                            <ListGroup>
                                {props.abstractFiles.map((file, index) => {
                                    return (
                                        <Row key={index}>
                                            <Col>
                                                <ListGroup.Item>{file.name}</ListGroup.Item>
                                            </Col>
                                            <Col>
                                                <Button onClick={() => openFile(file.downloadURL)}>View File</Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                                }

                            </ListGroup>
                        </>
                    }
                    <Button  className="m-3" variant="secondary" onClick={() => exportFile({header: [props.headerDetails], list: props.documentList, files: props.abstractFiles, reportTitle: title})}>Download Abstract</Button>
                </Card.Body>
                <Card.Footer className="text-muted">Prepared by Pacer Energy, LLC</Card.Footer>
            </Card>
    )
}

export default AdminViewClientAbstractHeader