import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import {collectionRefSignal, documentSignal} from "../../store/signalsStore";

export const queryForDoc = async (newDoc) => {
    let queryResult;
documentSignal.value.document = newDoc.document;
documentSignal.value.county = newDoc.county;
    if (newDoc.document !== undefined) {
        const docQuery = query(collectionRefSignal.value,
            where("document", "==", newDoc.document),
            where("county", "==", newDoc.county));
        const querySnapshot = await getDocs(docQuery)
        queryResult = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        if (queryResult[0] !== undefined) {
            return queryResult[0].id}
            }
}