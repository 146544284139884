import {addDoc, collection, doc, getDocs, getDoc, query, updateDoc, where} from "firebase/firestore";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {db} from "../services/firebase_config/firebase";
import {numberOfDocs, selectedCounty} from "../store/signalsStore";
import {getFullListForCounty} from "./queries/getFullListForCounty";
import {getCountyCollectionData} from "./queries/queryForCountyCollectionData";

const storage = getStorage();
const collectionRef = collection(db, "Documents");

export const addDocToCollection = async (document) => {
    let docRef = await addDoc(collectionRef, document);
    if (docRef) {
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return {id: docSnap.id, ...docSnap.data()}
        }
    }
};
export const updateDocInCollection = async (document, id) => {
    const docRef = doc(db, "Documents", id);
    let oldDoc;
    let docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        oldDoc = docSnap.data();
    }
    await updateDoc(docRef, document)
    return oldDoc
}

export const updateDocURLInCollection = async (document, id) => {
    const fileRef = ref(storage, `/Counties/${document.county}/${document.document}.pdf`);
    const docRef = doc(db, "Documents", id);
    if (fileRef) {
        await getDownloadURL(fileRef).then((downloadURL) => {
            if (downloadURL) {
                updateDoc(docRef, {downloadURL: downloadURL})
            }
        })
    }
}

export const updateCountyCollectionDataWithTotalNumberOfDocuments = async (county) => {
    const collectionRef = collection(db, "CountyCollectionData");
    const countyDataQuery = query(collectionRef,
        where("county", "==", county));
    const querySnapshot = await getDocs(countyDataQuery)
    if (!querySnapshot.empty) {
        console.log("updating")
        let queryResult = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        if (queryResult[0] !== undefined) {
            const docRef = doc(db, "CountyCollectionData", queryResult[0].id);
            getFullListForCounty(county).then((r) => updateDoc(docRef, {
                "documents": numberOfDocs.value,
                "lastUpdate": new Date(),
                "county": county
            }))
        }
    } else {
        console.log("adding")
        getFullListForCounty(county).then((r) => addDoc(collectionRef, {
            "county": county,
            "documents": numberOfDocs.value,
            "lastUpdate": new Date()
        }));
    }
    getCountyCollectionData(county)
}
