import React, {useEffect, useState} from 'react';
import {Card, Button, ListGroup, Col, Row} from "react-bootstrap"

import {addDoc, collection, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import {exportFile} from "../../features/Excel/exports/exportWithLinks";
import {addClient, getPermissions} from "../../utils/functions";
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from "file-saver";
import {PDFDocument} from 'pdf-lib';
import axios from 'axios';
import {queryForClientData} from "../../utils/queries/QueriesForUsersAndClients";
import {headerDetailsSignal} from "../../store/signalsStore";

// import {mergePdfs} from "./utils/mergePdf";
const headerDetails = headerDetailsSignal.value
const AbstractHeader = (props) => {
const [reportTitle, setReportTitle] = useState("Runsheet for Abstract of Title ")
const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"
    const openFile = (downloadURL) => {
        window.open(downloadURL, '_blank');
    }
    const [clientId, setClientId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
let headerData;
    const createClientReport = async (headerArr, documents, abstractFiles) => {
        headerData = {
            project: headerArr.project,
            acreage: headerArr.acreage,
            county: headerArr.county,
            date: headerArr.date,
            client: headerArr.client,
            lastPage: headerArr.lastPage ?? "",
            section: headerArr.section,
            townshipAndRange: headerArr.townshipAndRange,
        }
            let clientResults = [];
        let clientIdResult;
        const getClients = async () => {
            const collectionRef = collection(db, "clientData");
            const clientQuery = query(collectionRef)
            const querySnapshot = await getDocs(clientQuery)
            if (!querySnapshot.empty) {
                querySnapshot.docs.map(doc => {
                    clientResults.push({...doc.data(), id: doc.id})
                    return null
                })
            }
            clientResults = [...new Set(clientResults)]
            if (clientResults.find((client) => client.name === headerData.client)) {
                 setClientId(clientResults.find((client) => client.name === headerData.client).name)
            }
            else {
                await addClient(undefined, headerData.client, "", "")
                setClientId(headerData.client)
            }
        };
        await getClients()
        const collectionRef = collection(db, "ClientReports");
        //query against project name
        //if found, update
        //if not:
        const docQuery = query(collectionRef,
            where("project", "==", headerData.project),
            where("client", "==", headerData.client));
      const snapshot = await getDocs(docQuery);
        if (!snapshot.empty) {
            const docRef = doc(db, "ClientReports", snapshot.docs[0].id);
            updateDoc(docRef, {client: headerData.client, project: headerData.project, header: headerData, documents, abstractFiles, reportTitle});
            return alert(`${headerData.project} updated`)
        } else {
        addDoc(collectionRef, {client: headerData.client, project: headerData.project, header: headerData, documents, abstractFiles, reportTitle}).then(() => {
            return alert(`${headerData.project} added`)
        });
        }
    };


    const mergePdfs = async (pdfData) => {
        try {
            const response = await axios.post('https://spichersolutions.net:3535/merge-pdfs', pdfData,
                { responseType: 'blob' });
            const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
            saveAs(pdfBlob, 'merged.pdf');
        } catch (e) {
            console.error('Error while merging PDFs:', e);
        }
    };


    useEffect(() => {
        props.updated ?  setReportTitle("Runsheet for Updated Abstract of Title ") : setReportTitle("Runsheet for Abstract of Title ");
    }, [props.updated]);
    useEffect(() => {
    props.limited ? setReportTitle("Runsheet for Limited Abstract of Title ") : setReportTitle("Runsheet for Abstract of Title ");
    }, [props.limited]);

    return (
        <>
            <Card>
                <Card.Header> {reportTitle}: {headerDetails.client ?? ""}</Card.Header>
                <Card.Body>
                    <Card.Title>Project: {headerDetails.project ?? ""}</Card.Title>
                    <Card.Title>Pertaining to:</Card.Title>
                    <ListGroup>
                        <ListGroup.Item>Tract 1: {headerDetails.townshipAndRange ?? ""}</ListGroup.Item>
                        <ListGroup.Item>Section {headerDetails.section ?? ""}</ListGroup.Item>
                        <ListGroup.Item>County/State: {headerDetails.county ?? ""} </ListGroup.Item>
                        <ListGroup.Item>Containing approximately {headerDetails.acreage ?? ""} acres, more or less
                        </ListGroup.Item>
                        <ListGroup.Item> From: {headerDetails.date ?? ""}</ListGroup.Item>
                        <ListGroup.Item> Last Page: {headerDetails.lastPage ?? ""}</ListGroup.Item>
                    </ListGroup>
                    {props.abstractFiles[0] === undefined ? <></> :
                        <>
                            <Card.Title style={{marginTop: "10px"}}>Additional Files:</Card.Title>
                            <ListGroup>
                                {props.abstractFiles.map((file, index) => {
                                    return (
                                        <Row key={index}>
                                            <Col style={{maxWidth: "100px"}}>
                                                <p>{file.type}:</p>
                                            </Col>
                                            <Col>
                                                <ListGroup.Item>{file.name}</ListGroup.Item>
                                            </Col>
                                            <Col>
                                                <Button onClick={() => openFile(file.downloadURL)}>View File</Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                                }

                            </ListGroup>
                        </>
                    }

<Col>
                    <Button className="m-3" onClick={() => {
                        props.handleShow()
                    }} variant="primary">Report Setup</Button>

</Col>


                    <Button className="m-3" variant="secondary"
                            onClick={() => exportFile({
                                header: [headerDetails],
                                list: props.documentList,
                                files: props.abstractFiles,
                                reportTitle: reportTitle
                            })}>Download
                        Abstract Sheet
                    </Button>
                    <Button className="m-3" variant="secondary" onClick={async () => {
                        setIsLoading(true);

                        //catch missing download URL

                        const urls = [];
                        props.documentList.map((doc) => {
                            if (doc.downloadURL === "") {
                                doc.downloadURL = `${storageURL}/Counties/${headerDetails.county}/${doc.document}.pdf`
                            }
                            urls.push({url: doc.downloadURL, document: doc.document})
                        });
                            await mergePdfs(urls);
                        setIsLoading(false);
                    }}>
                        {isLoading ? <CircularProgress size={24} /> : 'Create Master PDF'}
                    </Button>

{/*                    <Col>*/}
{/*                    <Button className="m-3" onClick={()=> window.open("https://firebasestorage.googleapis.com/v0/b/documentdatabase-d2450.appspot.com/o/Document_List.xlsx?alt=media&token=133f686d-fa7c-4f1b-965c-209b97c6b149&_gl=1*f9hz92*_ga*NzUxMzE5NDcxLjE2NzM0NzI1NTk.*_ga_CW55HF8NVT*MTY4NjE3Nzc4MS42MC4xLjE2ODYxNzkwNjcuMC4wLjA.")}>Abstract Document List template</Button>*/}

{/*</Col>*/}




                </Card.Body>
                <Card.Footer className="text-muted">Prepared by Pacer Energy, LLC</Card.Footer>
            </Card>

        </>
    )
}

export default AbstractHeader