import {Card, Row, Col, Container} from "react-bootstrap";
import OilAndGasImg from "../../../assets/OilAndGas.JPG";
import PacerMenu from "../../Menu";
import {useEffect} from "react";
const OilAndGas = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>

            <PacerMenu/>
            <Row className="justify-content-center" >
                <Card className="col-8 col-md-8">
                    <div
                        style={{
                            backgroundImage: `url(${OilAndGasImg})`,
                            backgroundPosition: 'center bottom',
                            backgroundSize: 'cover',
                            height: '400px',
                            width: '100%'
                        }}
                    />
                    <Card.Body>
                        <Card.Title className="text-center">Oil & Gas</Card.Title>
                        <Row>
                            <Col className="text-center px-md-3">Abstracts of Title</Col>
                            <Col className="text-center px-md-3">Lease Acquisition</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Acquisition/Divestiture</Col>
                            <Col className="text-center px-md-3">Mineral Title Research</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Damage Settlement</Col>
                            <Col className="text-center px-md-3">Salt Water Disposal</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Drone Imaging Services</Col>
                            <Col className="text-center px-md-3">Seismic/Survey Access</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Due Diligence</Col>
                            <Col className="text-center px-md-3">Surface Inspections</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Federal/State Permitting</Col>
                            <Col className="text-center px-md-3">Surface Site Acquisition</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">GIS Mapping</Col>
                            <Col className="text-center px-md-3">Title Curative</Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}
export default OilAndGas;