import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import center_logo from "../../../assets/primaryPacerLogo.png"

const LogoImageComponent = () => {
    const [size, setSize] = useState({width: 0, height: 0});
    const location = useLocation();

    useEffect(() => {
        if(location.pathname !== '/pacerhome') {
            const image = new Image();
            image.src = center_logo;
            setSize({
                width: image.width / 30,
                height: image.height / 30
            });
        } else {
            const image = new Image();
            image.src = center_logo;
            image.onload = () => {
                setSize({
                    width: image.width / 10,
                    height: image.height / 10
                });
            };
        }
    }, [location]);

    return (
        <img
            className="logo_image"
            src={center_logo}
            alt="pacer logo"
            style={{ width: `${size.width}px`, height: `${size.height}px` }}
        />
    );
}

export default LogoImageComponent