import {Alert} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React, {useContext, useEffect} from "react";
import {AppState} from "../../App";
import {useSignals} from "@preact/signals-react/runtime";
import io from 'socket.io-client';


const AlertBox = () => {
    useSignals();
    const appState = useContext(AppState)

    useEffect(() => {
        // const socket = io('https://spichersolutions.net:4545');
        const socket = io('https://spichersolutions.net:4545');

        socket.on("pdfMergeProgress", (data) => {
            // Update your state here
            appState.alertMessage.value = data;
            appState.alertSeverity.value = "info";
        });

        // When the component is unmounted, we should disconnect from the socket server.
        return () => {
            socket.disconnect();
        }
    }, []);
    return (
        <>
        <div className="alertbox" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {appState.alertMessage.value !== "" ? <Alert variant="outlined"
                                                         severity={appState.alertSeverity.value}>{appState.alertMessage.value}</Alert> : <></>}
        </div>
    <div className="alertbox" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        {appState.alertProgress.value !== 0 ? <LinearProgress sx={{width: "80%", height: "50px"}} variant="determinate"
                                                              value={appState.alertProgress.value}/> : <></>}
    </div>
        </>
)
}
export default AlertBox
