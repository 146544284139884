import { Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useLocation, useNavigate} from "react-router-dom";
import {addDoc, collection, doc, updateDoc} from "firebase/firestore";
import {db} from "../../../services/firebase_config/firebase";

const EditClient = () => {
const location = useLocation();
const navigate = useNavigate();
    const [Id, setId] = useState("");
    const [clientName, setClientName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");

    const theme = createTheme();
    const handleSubmit = async (e) => {
        e.preventDefault()
        const clientUpdate = {name: clientName, phone: phone, address: address}

            if (location.state.clientName) {
                const docRef = doc(db, "clientData", `${Id}`);
                updateDoc(docRef, clientUpdate);
            }
            navigate('/admin/adduser', {state:{clientName: location.state.clientName}})
    }

    useEffect(() => {
        if (location.state.clientId !== undefined && location.state.clientList !== undefined) {

        const client = (location.state.clientList.find((client) =>  client.id === location.state.clientId));
        setClientName(client.name);
        setAddress(client.address);
        setPhone(client.phone);
        setId(client.id)
        }
    }, [location.state.clientName]);


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
        <Box component="form"
         onSubmit={handleSubmit}
         sx={{
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
         }}
    >
        <Grid container spacing={2}>
            <Typography>Edit {clientName}</Typography>
            <Grid item xs={12}>
                <TextField
                    label={"Client"}
                    value={clientName}
                    required
                    fullWidth
                    onChange={(e) => setClientName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={"Address"}
                    value={address}
                    required
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={"Phone"}
                    value={phone}
                    required
                    fullWidth
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Grid>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Update Client
            </Button>
        </Grid>
    </Box>
            </Container>
        </ThemeProvider>
    )
}

export default EditClient;