import {Card, Row, Col, Container} from "react-bootstrap";
import Windmill from "../../../assets/Windmill.jpeg";
import PacerMenu from "../../Menu";
import {useEffect} from "react";
const AlternativeEnergy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
            <Row className="justify-content-center">
                <Card className="col-8 col-md-8">
                    <div
                        style={{
                            backgroundImage: `url(${Windmill})`,
                            backgroundPosition: 'center bottom',
                            backgroundSize: 'cover',
                            height: '400px',
                            width: '100%'
                        }}
                    />
                    <Card.Body>
                        <Card.Title className="text-center">Alternative Energy</Card.Title>
                        <Row>
                            <Col className="text-center px-md-3">Environmental/Wildlife Consulting</Col>
                            <Col className="text-center px-md-3">Regulatory Consulting</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">GIS Mapping</Col>
                            <Col className="text-center px-md-3">Surface Access</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Permitting</Col>
                            <Col className="text-center px-md-3">Transmission Line Connections</Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}
export default AlternativeEnergy;