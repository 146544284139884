import {Card, Row, Col, Container} from "react-bootstrap";
import ROW_image from "../../../assets/ROW.jpeg";
import PacerMenu from "../../Menu";
import {useEffect} from "react";
const ROW = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
            <Row className="justify-content-center">
                <Card className="col-8 col-md-8">
                    <div
                        style={{
                            backgroundImage: `url(${ROW_image})`,
                            backgroundPosition: 'center bottom',
                            backgroundSize: 'cover',
                            height: '400px',
                            width: '100%'
                        }}
                    />
                    <Card.Body>
                        <Card.Title className="text-center">Right-of-Way</Card.Title>
                        <Row>
                            <Col className="text-center px-md-3">Cellular Site Acquisition</Col>
                            <Col className="text-center px-md-3">PipeLine Right-of-Way</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Easement Acquisition</Col>
                            <Col className="text-center px-md-3">Site Evaluation</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Gathering Systems</Col>
                            <Col className="text-center px-md-3">Transmission Line</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Fiber Optics</Col>
                            <Col className="text-center px-md-3">Well Connects</Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}
export default ROW;