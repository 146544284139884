import {queryForDocListDocuments} from "../../../utils/queries/QueryForDocListDocuments";
import {queryForDuplicateDocuments} from "../../../utils/queries/QueryForDuplicateDocuments";
import {queryForAbstractReportDocuments} from "../../../utils/queries/QueryForAbstractReportDocuments";
import {ExcelRenderer} from "react-excel-renderer";
import {useContext} from "react";
import {AppState} from "../../../App";

export const importAbstractDocListChangeHandler = (e,setDocQueryResults) => {
    e.preventDefault();
    ExcelRenderer(e.target.files[0], (err, resp) => {
        if (err) {
            console.log(err);
        } else {
            queryForDocListDocuments(
              resp.rows,
                setDocQueryResults,
            )
        }
    });
};

export const importDocumentChangeHandler = (e, showDuplicates, replaceAll, verifyAll, appState) => {
    e.preventDefault();
    ExcelRenderer(e.target.files[0], (err, resp) => {
        if (err) {
            console.log(err);
        } else {
            appState.alertMessage.value = "querying...";
            appState.alertSeverity.value = "info";
            appState.alertProgress.value = 15;
             queryForDuplicateDocuments(
                resp.rows,
                showDuplicates,
                replaceAll,
                verifyAll,
                appState
            )
        }

    });

};

export const importAbstractReportChangeHandler = (e, setDocQueryResults, docURL, setDocURL) => {
    e.preventDefault();
    let fileName = e.target.files[0].name.split(".x")[0];
    ExcelRenderer(e.target.files[0], (err, resp) => {
        if (err) {
            console.log(err);
        } else {
            queryForAbstractReportDocuments(
                resp.rows,
                setDocQueryResults,
                docURL,
                setDocURL,
                fileName
            )
        }
    });
};