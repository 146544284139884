import Excel from 'exceljs';
import {zipFiles} from "../../../utils/functions";
import {dunnCountyZipped, weldCountyZipped} from "./countyFormats";

export const exportZipFile = (data) => {

    let workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(data.header[0].project);

    switch (data.header[0].county) {
        case 'Dunn County, North Dakota':
            dunnCountyZipped(sheet, data)
            break;
        case 'Weld County, Colorado':
            weldCountyZipped(sheet, data)
            break;
        default:
            weldCountyZipped(sheet, data);
    }


    const excelBuffer = workbook.xlsx.writeBuffer();
    zipFiles(data, excelBuffer);


};