import {Button, Col, Modal, Row} from "react-bootstrap";
import {importAbstractDocListChangeHandler} from "../../../features/Excel/imports/importChangeHandlers";
import EditAbstractHeader from "../EditAbstractHeader";
import React from "react";

export const importAbstractModal = (
    showEditHeader,
    handleCloseEditHeader,
    docQueryResults,
    setDocQueryResults,
    abstractFiles,
    setAbstractFiles,
    setClient,
    reportList,
    setReport,
    report,
    docURL,
    setDocURL,
    limited,
    setLimited,
    updated,
    setUpdated
) => {

    return (
        <Modal show={showEditHeader} size="xl" onHide={handleCloseEditHeader}>
            <Modal.Header closeButton>

                <h1>Report Setup</h1>
            </Modal.Header>

            <Row>
                <Col style={{marginTop: "20px", marginLeft: "20px"}}>

                            <>
                                <input type="file" id="file2" name="file2" style={{display: "none"}} accept=".xlsx"
                                       onChange={(e) => {
                                           setAbstractFiles([])
                                           importAbstractDocListChangeHandler(e, setDocQueryResults,)
                                       }}
                                />
                                <Button className={"m-3"}>
                                    <label htmlFor="file2">Choose Doc List for Import</label>
                                </Button>
                            </>

                            {/*<Col>*/}
                            {/*    <input type="file" id="file3" name="file3" style={{display: "none"}} accept=".xlsx"*/}
                            {/*           onChange={(e) => {*/}
                            {/*               setAbstractFiles([])*/}
                            {/*               importAbstractReportChangeHandler(*/}
                            {/*               e,*/}
                            {/*                   setDocQueryResults,*/}
                            {/*                   docURL,*/}
                            {/*                   setDocURL*/}
                            {/*           )}}*/}
                            {/*               />*/}
                            {/*    /!*<Button className={"m-3"}>*!/*/}
                            {/*    /!*    <label htmlFor="file3">Choose Abstract for Import</label>*!/*/}
                            {/*    /!*</Button>*!/*/}
                            {/*</Col>*/}

                    {/*<p>or Load Report:</p>*/}
                    {/*<SelectClientForReport setAbstractFiles={setAbstractFiles} setDocumentList={setDocQueryResults}/>*/}
                </Col>

            </Row>
            <Modal.Body>

                <EditAbstractHeader
                                    abstractFiles={abstractFiles} setAbstractFiles={setAbstractFiles}
                                    setLimited={setLimited} limited={limited} setUpdated={setUpdated} updated={updated}

                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditHeader}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
}