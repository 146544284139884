import {collection, getDoc, getDocs, query, doc, where} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import {getClientNameFromLocalStorage} from "../functions";



export const queryForClientList = (setClients) => {
    let clientResults = ["Select Client"]

    const getClients = async () => {
        const collectionRef = collection(db, "ClientReports");
        const clientQuery = query(collectionRef)
        const querySnapshot = await getDocs(clientQuery)
        if (!querySnapshot.empty) {
            querySnapshot.docs.map(doc => {
                clientResults.push(doc.data().client)
                return null
            })
        }
        clientResults = [...new Set(clientResults)]
        setClients(clientResults)
    };
    getClients()

};

export const queryForClientData = (setClients) => {
    let clientResults = [{name: "Select Client"}]

    const getClients = async () => {
        const collectionRef = collection(db, "clientData");
        const clientQuery = query(collectionRef)
        const querySnapshot = await getDocs(clientQuery)
        if (!querySnapshot.empty) {
            querySnapshot.docs.map(doc => {
                clientResults.push({...doc.data(), id: doc.id})
                return null
            })
        }
        clientResults = [...new Set(clientResults)]
        setClients(clientResults)
    };
    getClients()

};

export const queryForAdminClientList = (setClients) => {
    let clientResults = [{client:"Select Client"}]

    const getClients = async () => {
        const collectionRef = collection(db, "ClientReports");
        const clientQuery = query(collectionRef)
        const querySnapshot = await getDocs(clientQuery)
        if (!querySnapshot.empty) {
            querySnapshot.docs.map(doc => {
                // clientResults.push(doc.data().client)
                clientResults.push({...doc.data(), id: doc.id})
                return null
            })
        }
        clientResults = [...new Set(clientResults)]
        setClients(clientResults)
    };
    getClients()
};

export const getClientReport = (id, setReport) => {
    let report = ["Select Report"]
    const getReport = async () => {
        report = await getDoc(doc(db, "ClientReports", id));
        if (report.exists()) {
            report = report.data()
        } else {
            report = ["xx"]
        }
        setReport(report)
    };
    getReport()
}

export const queryDatabaseForClientName = (id, adduser, setClientName) => {
    let client;
    let name;
    const getName = async () => {
         client = await getDoc(doc(db, "clientData", id));
        if (client.exists() ) {
            if (!adduser) {
            localStorage.setItem("clientName", JSON.stringify(client.data().name))
            } else {
                setClientName(client.data().name)
            }
        }

    };
    getName()
}
export const getClientReports = async (client, setReports) => {
    let reports = [{header: {project:"Select Report"}, id:  "1"}]
    const collectionRef = collection(db, "ClientReports");
    const reportQuery = query(collectionRef,
        where("client", "==", client));
    const querySnapshot = await getDocs(reportQuery)
    if (!querySnapshot.empty) {
        querySnapshot.docs.map(doc => {
            reports.push({...doc.data(), id: doc.id})
            return null
        })
    } else {
        reports = [{header: {project: "no results found"}}]
    }
    setReports(reports)
};

// export const getClientReportsFromEmail = async (email, setReports) => {
//     let reports = [{header: {project:"Select Report"}, id:  "1"}]
//     const collectionRef = collection(db, "ClientReports");
//     const reportQuery = query(collectionRef,
//         where("users", "array-contains", email));
//     const querySnapshot = await getDocs(reportQuery)
//     if (!querySnapshot.empty) {
//         querySnapshot.docs.map(doc => {
//             reports.push({...doc.data(), id: doc.id})
//             return null
//         })
//     } else {
//         reports = [{project: "no results found"}]
//     }
//     setReports(reports)
// };

export const getClientReportsFromClientName = async (setReports) => {
    let reports = [{header: {project:"Select Report"}, id:  "1"}]
    let clientName = getClientNameFromLocalStorage()
    console.log(clientName)
    const collectionRef = collection(db, "ClientReports");
    const reportQuery = query(collectionRef,
        where("client", "==", clientName));
    const querySnapshot = await getDocs(reportQuery)
    if (!querySnapshot.empty) {
        querySnapshot.docs.map(doc => {
            reports.push({...doc.data(), id: doc.id})
            return null
        })
    } else {
        reports = [{project: "no results found"}]
    }
    setReports(reports)
};

export const queryForUserList = (setUserList,clientId) => {
    let userResults = [{firstName: "Select", lastName: "User"}]

    const getUsers = async () => {
        const collectionRef = collection(db, "userData");
        const clientQuery = query(collectionRef)
        const querySnapshot = await getDocs(clientQuery)
        if (!querySnapshot.empty) {

            querySnapshot.docs.map(doc => {
                if (doc.data().clientId === clientId)
                    userResults.push({...doc.data(), id: doc.id})
                return null
            })
        }
        userResults = [...new Set(userResults)]
        setUserList(userResults)
    };
    getUsers()

};

// export const checkAdminStatus = async (currentUser) => {
//     // if (currentUser === undefined ) return false;
//     // if (currentUser.email === undefined ) return false;
//     const adminRef = collection(db, "admin");
//     const userQuery = query(adminRef,
//         where("users", "array-contains", currentUser.email));
//     const querySnapshot = await getDocs(userQuery)
//     if (querySnapshot === undefined) return false;
//     if (!querySnapshot.empty) {
//       return true
//         }
//        return false
// };

