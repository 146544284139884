import {Card, Row, Col, Container, Image} from "react-bootstrap";
import joseph_headshot from "../../../assets/Tessaro,+Joseph.JPG";
import lindsey_headshot from "../../../assets/Stinson,+LIndsey.jpg";
import memberMark from "../../../assets/memberMark.jpg"
import corporatePartner from "../../../assets/CorporatePartnerLogo.png"

import PacerMenu from "../../Menu";
import {useEffect} from "react";
const Management = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
            <Container style={{ maxWidth: "80%", margin: "0 auto" }}>
                <Row>
                    <Col>
                        <Card >
                            <Card.Body>
                                <Card.Title  style={{
                                    textAlign: "center"
                                }}
                                >Lindsey Stinson</Card.Title>
                            </Card.Body>
                            <Card.Img style={{ width: '80%', height:'600px', objectFit:'cover', objectPosition:'top', marginLeft: '10%', marginRight: '10%' }}  src={lindsey_headshot} />
                                <Card.Text>
                                    <p style={{
                                        textAlign: "center",
                                        fontStyle: "italic"
                                    }}>
                                        310 S. Miller Ave.,<br/>
                                        Suite A<br/>
                                        Gillette, WY 82716<br/>
                                        <a href="mailto:lindsey@pacerenergy.com">lindsey@pacerenergy.com</a>
                                    </p>
                                </Card.Text>
                            <Card.Img variant="top" src={memberMark} />

                        </Card>
                    </Col>
                    <Col>
                        <Card >
                            <Card.Body>
                                <Card.Title  style={{
                                    textAlign: "center"
                                }}>Joseph Tessaro</Card.Title>
                            </Card.Body>
                            <Card.Img style={{ width: '80%', height:'600px', objectFit:'cover', marginLeft: '10%', marginRight: '10%' }} src={joseph_headshot} />
                                <Card.Text>
                                    <p style={{
                                        textAlign: "center",
                                        fontStyle: "italic"
                                    }}>1603 Capitol Ave.,
                                        <br/>
                                        Suite 209
                                        <br/>
                                        Cheyenne, WY 82001
                                        <br/>
                                        <a href="mailto:joseph@pacerenergy.com">joseph@pacerenergy.com</a>
                                    </p>
                                </Card.Text>
                            <Card.Img variant="top" src={memberMark} />
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ width: '80%', backgroundColor: 'lightgrey' }}>
                        <Row style={{ maxWidth: '100%', padding: '1rem' }}>
                            <Col>Pacer Energy is honored to be recognized as one of AAPL’s Premier Corporate Partners.
                                We recognize the importance of promoting the highest standards of performance for all land professionals, and encourage the sound and ethical stewardship of our energy and mineral resources by requiring adherence to the AAPL Code of Ethics and Standards of Practice.
                                Learn more about the AAPL’s Corporate Partner Recognition Program.</Col>
                        </Row>
                        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '100%', padding: '1rem'}}>
                            <Image src={corporatePartner} style={{ maxWidth: '25%'}}/>

                        </Row>
                    </Card>
                </div>
            </Container>

        </Container>
    )
}
export default Management;