import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
function AboutNavMenu() {
    return (
        <Navbar  expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                <Nav style={{ backgroundColor: 'lightgray', borderRadius: '5px' }}>
                    <NavLink to="/pacerhome" className="nav-link" activeClassName="active">Home</NavLink>
                    <div className="border-right" />
                    <NavLink to="/management" className="nav-link" activeClassName="active">Management</NavLink>
                    <div className="border-right" />
                    <NavLink to="/contactus" className="nav-link" activeClassName="active">Contact Us</NavLink>
                    <div className="border-right" />
                    <NavLink to="/resources" className="nav-link" activeClassName="active">Resources</NavLink>
                    <div className="border-right" />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
export default AboutNavMenu;