import {Container, Col, Row, Carousel, Image, Card} from "react-bootstrap";
import main_page_image1 from "../../assets/canyon.jpg"
import main_page_image2 from "../../assets/powerLines.jpg"
import map from "../../assets/Pacer+Map+(2020).png";
import explore_logo from "../../assets/Explore+The+West+Logo.png"
import Windmill from "../../assets/Windmill.jpeg";
import devilstower from "../../assets/devilstower.jpeg"
import OilAndGas from "../../assets/OilAndGas.JPG";
import dumpTruck from "../../assets/Dump+Truck.jpg"
import droneServices from "../../assets/DroneServices.JPG"
import PacerMenu from "../Menu";

import { BsDash } from "react-icons/bs";
import IndustryNavMenu from "./Components/IndustryNavMenu";
import AboutNavMenu from "./Components/AboutNavMenu";
import {useEffect} from "react";

const PacerHome = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
            <div className="main_page1">
                <div style={{position: 'relative'}}>
                    <Carousel controls={false}>
                        <Carousel.Item>
                            <img
                                className="image"
                                src={main_page_image1}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="image"
                                src={main_page_image2}
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="image"
                                src={devilstower}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="image"
                                src={Windmill}
                                alt="Fourth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="image"
                                src={OilAndGas}
                                alt="Fifth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="image"
                                src={dumpTruck}
                                alt="Sixth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="image"
                                src={droneServices}
                                alt="Seventh slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                    <Image
                        className="explore_logo_center_screen"
                        src={explore_logo}
                    />
                </div>
                <div className="main_page1_subsection">
                    <Row className="home_cards carousel2">
                        <Col xs={1}></Col>
                        <Col xs={2}>LAND</Col>
                        <Col xs={2}><BsDash/></Col>
                        <Col xs={2}>MINERALS</Col>
                        <Col xs={1}></Col>
                        <Col xs={2}><BsDash/></Col>
                        <Col xs={2}>TITLE</Col>
                    </Row>
                        <AboutNavMenu/>
                    <IndustryNavMenu/>
                    <div className="wide_dash"/>

                    <Row className="width: 80%">
                        <Col xs={6} className="fontstyle">

                            <h3 className="font-weight: lighter ">
                                Headquartered in Wyoming, Pacer Energy is uniquely positioned to effectively handle our
                                client’s
                                needs throughout the United States.</h3>

                        </Col>
                        <Col xs={6}>
                            <Image className={"map_image"} src={map}></Image>
                        </Col>
                    </Row>
                    <Row>

                        <h3 className={"pt-5 fontstyle"}>
                            Pacer Energy serves its clients by providing critical land services relating to surface and
                            mineral title, right-of-way, oil and gas leasing, and surface negotiations.

                        </h3>
                    </Row>


                </div>
            </div>
            <div style={{width: '80%', margin: '0 auto'}}>
                <Card className={"carousel2 fontstyle"} bg={"light"}>
                    <Card.Body>
                        <Card.Title style={{textAlign: "center", fontSize: "30px"}}>Our Services</Card.Title>
                        <div className="wide_dash_grey"/>
                        <Row>
                            <Col><Card.Subtitle style={{textAlign: "center", fontSize: "25px"}}>Mineral Title</Card.Subtitle></Col>
                            <Col><Card.Text style={{fontSize: "20px"}}>Pacer Energy specializes in surface and mineral title examination from
                                patent to present.</Card.Text></Col>
                        </Row>
                        <div className="wide_dash_grey"/>
                        <Row>
                            <Col><Card.Subtitle style={{textAlign: "center", fontSize: "25px"}}>Acquisition Due Diligence</Card.Subtitle></Col>
                            <Col><Card.Text style={{fontSize: "20px"}}>Mergers and acquisitions require significant due diligence in order for the
                                buyer to fully understand what it is they are buying, and the nature and extent of the
                                company’s contingent liabilities and problematic contracts.</Card.Text></Col>
                        </Row>
                        <div className="wide_dash_grey"/>
                        <Row>
                            <Col><Card.Subtitle style={{textAlign: "center", fontSize: "25px"}}>Right Of Way</Card.Subtitle></Col>
                            <Col><Card.Text style={{fontSize: "20px"}}>From pipelines and road access to transmission lines and fiber optics. Pacer
                                Energy is equipped to handle all right-of-way projects, large or
                                small.</Card.Text></Col>
                        </Row>
                        <div className="wide_dash_grey"/>
                        <Row>
                            <Col><Card.Subtitle style={{textAlign: "center", fontSize: "25px"}}>Abstracts of Title</Card.Subtitle></Col>
                            <Col><Card.Text style={{fontSize: "20px"}}>Pacer Energy provides detailed abstracts allowing operators to lower the
                                costs of Drilling Title Opinions.</Card.Text></Col>
                        </Row>
                        <div className="wide_dash_grey"/>
                        <Row>
                            <Col><Card.Subtitle style={{textAlign: "center", fontSize: "25px"}}>Aerial Drone Imaging</Card.Subtitle></Col>
                            <Col><Card.Text style={{fontSize: "20px"}}>Pacer Energy provides aerial drone imaging and mapping services.</Card.Text></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>

        </Container>


)

}

export default PacerHome


