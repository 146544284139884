import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useAuth} from "../../../contexts/AuthContext"
import { useLocation } from "react-router-dom";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../../services/firebase_config/firebase";
import {getClientNameFromLocalStorage, getPermissions} from "../../../utils/functions";
import {queryDatabaseForClientName} from "../../../utils/queries/QueriesForUsersAndClients";


const AddPacerUser = (props) => {
    const location = useLocation();

    const {createUser} = useAuth();
    const [email, setEmail] = useState("");
    const [clientName, setClientName] = useState("")
    const [clientId, setClientId] = useState("")
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [canAddUsers, setCanAddUsers] = useState(false);
    const [canCreateClientReports, setCanCreateClientReports] = useState(false);

    let isClient = false;

    const theme = createTheme();
    const handleSubmit =  (e) => {
        e.preventDefault()
        try {
            if (clientId === undefined) setClientName("Pacer");
            createUser(email, firstName + "_" + clientName, firstName, lastName, canAddUsers, canCreateClientReports, clientId)

        } catch {
            console.log("error")
        }
        let userEmails = [];
        if (props.report !== undefined) {

            if (props.report.users !== undefined) {
                userEmails = props.report.users;
                userEmails.push(email);
                props.report.users = userEmails;
                const docRef = doc(db, "ClientReports", `${props.report.id}`);
                updateDoc(docRef, props.report);
            }
        }
    }
    useEffect(() => {
        if (location.state !== null) {
            setClientId(location.state.clientId)
            console.log(location.state)
            getClientNameFromLocalStorage(location.state.clientId, true,setClientName);
            queryDatabaseForClientName(location.state.clientId, true, setClientName)
        } else {setClientId("o6T5E8Eq2MWhP8GKrY08");
            setClientName("Pacer");
        }
    }, [location.state])

    return (
        <ThemeProvider theme={theme}>
                <CssBaseline/>

        <Box component="form"
         onSubmit={handleSubmit}
         sx={{
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
         }}
    >
        <Grid container spacing={2}>
            <Typography>Add {clientName} User</Typography>
            <Grid item xs={12}>
                <TextField
                    label={"First Name"}
                    required
                    fullWidth
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={"Last Name"}
                    required
                    fullWidth
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={"Email"}
                    required
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Grid>
                <Grid style={{marginLeft: "15px"}}>
                <FormControlLabel control={<Checkbox onChange={() => setCanAddUsers(!canAddUsers)}/>}
                                  label="Admin?"/>
            </Grid>

            { !getPermissions.isClient ? <></> :
                <Grid style={{marginLeft: "15px"}}>
                <FormControlLabel
                    control={<Checkbox onChange={() => setCanCreateClientReports(!canCreateClientReports)}/>}
                    label="Client Reporting?"/>
            </Grid>}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Add User
            </Button>
        </Grid>
    </Box>
       </ThemeProvider>
    )
}

export default AddPacerUser;