export const emptyDocument =
    {
        instrument: "",
        grantor: "",
        grantee: "",
        effectiveDate: "",
        fileDate: "",
        book: "",
        page: "",
        county: "",
        state: "",
        documentNumber: "",
        comments: "",
        description: "",
        wol: "",
        id: ""
    };

export const emptyHeaderDetails =
    {
        project: "",
        county: "",
        townshipAndRange: "",
        section: "",
        acreage: "",
        date: "",
        lastPage: "",
    };