import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import {NavLink, useLocation} from 'react-router-dom';
function IndustryNavMenu() {
    const location = useLocation();
    return (
        <Navbar expand="lg" >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                <div style={{ background: "lightgray", borderRadius: '5px'}}>
                    <Nav>
                        {location.pathname !== "/pacerhome" ?
                        <NavLink to="/pacerhome" className="nav-link" activeClassName="active">Home</NavLink>
                        :
                        <></>}
                        <div className="border-right" /><NavLink to="/oilandgas" className="nav-link" activeClassName="active">Oil and Gas</NavLink>
                        <div className="border-right" />
                        <NavLink to="/mining" className="nav-link" activeClassName="active">Mining</NavLink>
                        <div className="border-right" />
                        <NavLink to="/rightofway" className="nav-link" activeClassName="active">Right Of Way</NavLink>
                        <div className="border-right" />
                        <NavLink to="/droneservices" className="nav-link" activeClassName="active">Drone Services</NavLink>
                        <div className="border-right" />
                        <NavLink to="/digitalimaging" className="nav-link" activeClassName="active">Digital Imaging</NavLink>
                        <div className="border-right" />
                        <NavLink to="/alternativeenergy" className="nav-link" activeClassName="active">Alternative Energy</NavLink>
                    </Nav>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
}
export default IndustryNavMenu;