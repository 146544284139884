import {Modal} from "react-bootstrap";
import React from "react";
import AddUserToReport from "../Reports/AddUserToReport";

export const AddUserToReportModal = (props) => {
    return (
        <Modal show={props.show} size="lg" onHide={props.handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <AddUserToReport handleClose={props.handleClose} report={props.report}/>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}