import {Row, Button} from "react-bootstrap";
import SelectClient from "./SelectClient";
import SelectUser from "./SelectUser";

import {useNavigate, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {Divider} from "@mui/material";
import addUser from "../AddUser";
import {getClientId, getPermissions} from "../../../../utils/functions";

const AdminMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [clientId, setClientId] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [userList, setUserList] = useState([]);
    const [clientList, setClientList] = useState([])

    useEffect(() => {
        if (getPermissions().isClient) {
            setClientId(getClientId())
        } else (setClientId("o6T5E8Eq2MWhP8GKrY08"))
    }, [getClientId()])

    const selectUserOnChange = (e: any) => {
        if (e.target.value !== "Select User")
            navigate("/admin/edituser", {state: {userId: e.target.value, userList: userList}})
    }
    const selectPacerUserOnChange = (e: any) => {
        if (e.target.value !== "Select User")
            navigate("/admin/editpaceruser", {state: {userId: e.target.value, userList: userList}})
    }
    const selectClientOnChange = (e: any) => {
        if (e.target.value !== "Select Client")
            setSelectedClient(e.target.value)
            navigate("/admin/editclient", {state: {clientId: e.target.value, clientList: clientList}})
    };
    return (
                <>
                                {location.pathname === "/admin/addpaceruser" ?
                                    <>
                    <Row className={"admin-menu"}>
                                    <Button onClick={() => navigate("/admin/addclient")}>Manage Clients</Button>
                    </Row>
                                        <Row className={"admin-menu"}>

                                <SelectUser onChange={(e: any) => {selectPacerUserOnChange(e)}} clientId={clientId} userList={userList}
                                            setUserList={setUserList}/>
                                        </Row>
                            </>
                            : <></>
                        }

                    {location.pathname === "/admin/editpaceruser" ?
                        <>
                            <Row className={"admin-menu"}>

                                <SelectClient onChange={(e:any) => {selectClientOnChange(e)}} clientList={clientList}
                                              setClientList={setClientList}/>
                            </Row>
                            <Row className={"admin-menu"}>

                                <SelectUser onChange={(e: any) => {selectPacerUserOnChange(e)}} clientId={clientId} userList={userList}
                                            setUserList={setUserList}/>
                            </Row>
                            <Row className={"admin-menu"}>
                                <Button onClick={() => navigate("/admin/addpaceruser", {state: {clientId: clientId}})}>Add
                                    User</Button>
                            </Row>
                        </>
                        : <></>
                    }

                    {location.pathname === "/admin/adduser" ?
                        <>
                            <Row className={"admin-menu"}>
                                <Button onClick={() => {
                                    //set client to "Pacer"
                                    setClientId("o6T5E8Eq2MWhP8GKrY08");
                                    navigate("/admin/addpaceruser", {state: {clientId: clientId}})
                                }}>Manage Pacer</Button>
                            </Row>
                            <Row className={"admin-menu"}>
                                <Button onClick={() => navigate("/admin/addclient")}>Manage Clients</Button>
                            </Row>
                            <Row className={"admin-menu"}>
                                <Button onClick={() => navigate("/admin/adduser", {state: {clientId: clientId}})}>Add
                                    User</Button>
                            </Row>
                            <Row className={"admin-menu"}>

                                <SelectUser onChange={(e: any) => {selectUserOnChange(e)}} clientId={clientId} userList={userList}
                                            setUserList={setUserList}/>
                            </Row>
                        </>
                        : <></>
                    }

                        {location.pathname === "/admin/edituser" ?
                            <>
                                <Row className={"admin-menu"}>
                                    <Button onClick={() => {
                                        //set client to "Pacer"
                                        setClientId("o6T5E8Eq2MWhP8GKrY08");
                                        navigate("/admin/addpaceruser", {state: {clientId: clientId}})
                                    }}>Manage Pacer</Button>
                                </Row>
                                <Row className={"admin-menu"}>

                                    <SelectUser onChange={(e: any) => {selectUserOnChange(e)}} clientId={selectedClient} userList={userList}
                                                setUserList={setUserList}/>
                                </Row>
                                <Row className={"admin-menu"}>

                                <SelectClient onChange={(e:any) => {selectClientOnChange(e)}} clientList={clientList}
                                              setClientList={setClientList}/>
                                </Row>
                            </>
                            : <></>
                        }
                        {location.pathname === "/admin/addclient" ?
                            <>
                                <Row className={"admin-menu"}>
                                <Button onClick={() => {
                                    //set client to "Pacer"
                                    setClientId("o6T5E8Eq2MWhP8GKrY08");
                                    navigate("/admin/addpaceruser", {state: {clientId: clientId}})
                                }}>Manage Pacer</Button>
                            </Row>

                                <Row className={"admin-menu"}>

                                    <SelectClient onChange={(e:any) => {selectClientOnChange(e)}} clientList={clientList}
                                                  setClientList={setClientList}/>
                                </Row>
                                </>
                                : <></>
                        }






                        {location.pathname === "/admin/editclient" ?
                            <>
                                <Button onClick={() => {
                                    //set client to "Pacer"
                                    setClientId("o6T5E8Eq2MWhP8GKrY08");
                                    navigate("/admin/addpaceruser", {state: {clientId: clientId}})
                                }}>Manage Pacer</Button>

                                <Row className={"admin-menu"}>

                                    <SelectClient onChange={(e:any) => {selectClientOnChange(e)}} clientList={clientList}
                                                  setClientList={setClientList}/>
                                </Row>

                                <Row className={"admin-menu"}>
                                        <SelectUser onChange={selectUserOnChange} clientId={selectedClient} userList={userList}
                                                    setUserList={setUserList}/>

                                </Row>
                                <Row className={"admin-menu"}>
                                <Button onClick={() => navigate("/admin/adduser", {state: {clientId: selectedClient}})}>Add
                                    User</Button>
                                </Row>
                            </>
                            : <></>
                        }
                        {/*        </>*/}
                        {/*    : <></>}*/}

                        {/*        /!*<SelectUser onChange={selectUserOnChange} clientId={clientId} userList={userList}*!/*/}
                        {/*        /!*            setUserList={setUserList}/>*!/*/}

</>
    )
}

export default AdminMenu