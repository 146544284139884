import {initializeApp} from "firebase/app";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getAnalytics, setUserProperties} from "firebase/analytics";
import {getStorage} from "firebase/storage"

//For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBBw3MIvogadFn6AB6EUfC4fHGwlhHNhHk",
    authDomain: "documentdatabase-d2450.firebaseapp.com",
    databaseURL: "https://documentdatabase-d2450-default-rtdb.firebaseio.com",
    projectId: "documentdatabase-d2450",
    storageBucket: "documentdatabase-d2450.appspot.com",
    messagingSenderId: "399743716705",
    appId: "1:399743716705:web:ff3af698d6da30d1720920",
    measurementId: "G-SRZ5L6367E"
};

const USE_FIREBASE_EMULATORS = false;

export const app = initializeApp(firebaseConfig);
const secondaryFbApp = initializeApp(firebaseConfig, "Secondary");
export const auth = getAuth(app);
export const storage = getStorage()
export const secondaryAuth = getAuth(secondaryFbApp);

if (USE_FIREBASE_EMULATORS) {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectAuthEmulator(secondaryAuth, "http://localhost:9099");
}

export const db = (() => {
    const db = getFirestore();
    if (USE_FIREBASE_EMULATORS)
        connectFirestoreEmulator(db, 'localhost', 8080);
    return db;
})();

const analytics = getAnalytics();
setUserProperties(analytics, {
    platform: 'web'
});
