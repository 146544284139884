import {Card, Row, Col, Container} from "react-bootstrap";
import DumpTruck from "../../../assets/Dump+Truck.jpg";
import PacerMenu from "../../Menu";
import {useEffect} from "react";
const Mining = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
            <Row className="justify-content-center">
                <Card className="col-8 col-md-8">
                    <div
                        style={{
                            backgroundImage: `url(${DumpTruck})`,
                            backgroundPosition: 'center bottom',
                            backgroundSize: 'cover',
                            height: '400px',
                            width: '100%'
                        }}
                    />
                    <Card.Body>
                        <Card.Title className="text-center">Mining</Card.Title>
                        <Row>
                            <Col className="text-center px-md-3">Abstracts of Title</Col>
                            <Col className="text-center px-md-3">Right of Way</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Acquisition/Divestiture</Col>
                            <Col className="text-center px-md-3">Runsheets</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Damage Settlement</Col>
                            <Col className="text-center px-md-3">Surface/Survey Access</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Project Management</Col>
                            <Col className="text-center px-md-3">Surface Site Acquisition</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">GIS Mapping</Col>
                            <Col className="text-center px-md-3">Title Curative</Col>
                        </Row>
                        <Row>
                            <Col className="text-center px-md-3">Mineral/Surface Title</Col>
                            <Col className="text-center px-md-3"></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}
export default Mining;