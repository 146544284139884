import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useLocation} from "react-router-dom";
import {getPermissions} from "../../../utils/functions";

const EditUser = () => {
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [canAddUsers, setCanAddUsers] = useState(false);
    const [canCreateClientReports, setCanCreateClientReports] = useState(false);
    const [alert, setAlert] = useState({message: "", severity: "success"});
    const theme = createTheme();
    const handleSubmit = async (e) => {
        e.preventDefault()
    }

    useEffect(() => {
        if (location.state.userId!== undefined && location.state.userList !== undefined) {
            const user = (location.state.userList.find((user) =>  user.id === location.state.userId));
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setCanAddUsers(user.permissions.canAddUsers)
            setCanCreateClientReports(user.permissions.canCreateClientReports)
        }
    }, [location.state.id]);
    return (
        <ThemeProvider theme={theme}>


            <Container component="main" maxWidth="xs">
                <CssBaseline/>
        <Box component="form"
         onSubmit={handleSubmit}
         sx={{
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
         }}
    >
        <Grid container spacing={2}>
            <Typography>Edit Pacer User</Typography>
            <Grid item xs={12}>
                <TextField
                    value={firstName}
                    required
                    fullWidth
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={lastName}
                    required
                    fullWidth
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Grid>

            <Grid style={{marginLeft: "15px"}}>
                <FormControlLabel control={<Checkbox checked={canAddUsers} onChange={() => setCanAddUsers(!canAddUsers)}/>}
                                  label="Admin?"/>
            </Grid>
            {!getPermissions.isClient ? <></> :
            <Grid style={{marginLeft: "15px"}}>
                <FormControlLabel control={<Checkbox checked={canCreateClientReports} onChange={() => setCanCreateClientReports(!canCreateClientReports)}/>}
                                  label="Client Reporting?"/>
            </Grid>
            }
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Edit User
            </Button>
        </Grid>
    </Box>
            </Container>
        </ThemeProvider>
    )
}

export default EditUser;