export const extractDoc = (document, indexOf, index, verifyAll) => {

    let book;
    let page;
    let reqNum; //courthouse requisition #
    let doc; // db doc identifier
    let verified = false
        //if there is a document (might be docNumber, might be book-page, assuming book-page is correct format)

    if (index >= indexOf.beginDocumentRowsIndex && index < indexOf.endDocumentRowsIndex) {


        if (document[indexOf.document] !== undefined) { //check we are dealing with a document:
if (document.county === "Cheyenne County, Nebraska") {
    if (document[indexOf.document].toString().includes("-")) {    // if document is identified by book-page, split then format correctly
        book = document[indexOf.document].split("-")[0].toString().padStart(5, "0");
        page = document[indexOf.document].split("-")[1].toString().padStart(4, "0");
        reqNum = document[indexOf.reqNumber].toString() ?? "";
        doc = document[indexOf.document].split("-")[0].toString().padStart(5, "0") + "-" + document[indexOf.document].split("-")[1].toString().padStart(4, "0");

    } else {    // if no dash, then document = req Number. Format book and page if they exist.
        book = document[indexOf.book] !== undefined && document[indexOf.book].toString() !== "-" ? document[indexOf.book].toString().padStart(5, "0") : "";
        page = document[indexOf.page] !== undefined && document[indexOf.page].toString() !== "-" ? document[indexOf.page].toString().padStart(4, "0") : "";
        reqNum = document[indexOf.document].toString() ?? "";
        doc = document[indexOf.document].toString();
    }
} else {

            if (document[indexOf.document].toString().includes("-")) {    // if document is identified by book-page, split then format correctly
                book = document[indexOf.document].split("-")[0].toString().padStart(4, "0");
                page = document[indexOf.document].split("-")[1].toString().padStart(4, "0");
                reqNum = document[indexOf.reqNumber] ? document[indexOf.reqNumber].toString() : "";
                doc = document[indexOf.document].split("-")[0].toString().padStart(4, "0") + "-" + document[indexOf.document].split("-")[1].toString().padStart(4, "0");

            } else {    // if no dash, then document = req Number. Format book and page if they exist.
                book = document[indexOf.book] !== undefined && document[indexOf.book].toString() !== "-" ? document[indexOf.book].toString().padStart(4, "0") : "";
                page = document[indexOf.page] !== undefined && document[indexOf.page].toString() !== "-" ? document[indexOf.page].toString().padStart(4, "0") : "";
                reqNum = document[indexOf.document].toString() ?? "";
                doc = document[indexOf.document].toString();
            }
}

        } else {    //if there is no document in the report

            if (document[indexOf.book] !== undefined && document[indexOf.book].length > 1 ) {   // if book exists, covert book page to correct format then make that the doc
                book = !Number.isNaN(document[indexOf.book]) ? document[indexOf.book].toString().padStart(4, "0") : document[indexOf.book].padStart(4, "0");
                page = !Number.isNaN(document[indexOf.page]) ? document[indexOf.page].toString().padStart(4, "0") : document[indexOf.page].padStart(4, "0");
                reqNum = "";
                doc = book + "-" + page;
                //if no book, make req number doc number
            } else {
                book = document[indexOf.book] ?? "";
                page = document[indexOf.page] ?? "";
                reqNum = document[indexOf.reqNumber] ?? "";
                doc = document[indexOf.reqNumber] ?? "";
            }
        }

        if (document[indexOf.comments + 1] !== undefined) {verified = true}
        if (verifyAll) verified = true;


    }
    return {
        reqNumber: reqNum ?? "" ,
        instrument: document[indexOf.instrument] ?? "",
        downloadURL: "",
        document: doc,
        grantor: document[indexOf.grantor] ?? "",
        grantee: document[indexOf.grantee] ?? "",
        fileDate: document[indexOf.fileDate] ?? "",
        receivedDate: document[indexOf.receivedDate] ?? "",
        effectiveDate: document[indexOf.effectiveDate] ?? "",
        book: book,
        page: page,
        county: indexOf.county ?? "",
        description: document[indexOf.description] ?? "",
        wol: document[indexOf.wol] ?? "",
        comments: document[indexOf.comments] ?? "",
        verified: verified
    }
}