import React, {useRef, useState}  from "react";
import {useAuth} from "../../contexts/AuthContext";
import {Card, Form, Button, Alert} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import AddUser from "../Admin/UserManagement/AddUser";

const AddUserToReport = (props) => {
    const emailRef = useRef()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
   const { createUser } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    // async function handleSubmit(e) {
    //     e.preventDefault()
    //
    //     try {
    //         setError("")
    //         setLoading(true)
    //         await createUser(emailRef, firstNameRef + "_" + props.report.clientName, firstNameRef, lastNameRef, canAddUsers, isClient, clientId)
    //
    //         navigate('/')
    //     } catch {
    //         setError("Failed to create an account")
    //     }
    //     let userEmails = [];
    //     if (props.report.users !== undefined) userEmails = props.report.users;
    //     userEmails.push(emailRef.current.value);
    //     props.report.users = userEmails;
    //         const docRef = doc(db, "ClientReports", `${props.report.id}`);
    //         updateDoc(docRef, props.report);
    //
    //     setLoading(false);
    //     if (error === "") props.handleClose();
    // }
    return (
        <>
            <AddUser/>
            {/*<Card>*/}
            {/*    <Card.Body>*/}
            {/*        <h2 className="text-center mb-4" >Add User to {props.report.header.project}</h2>*/}
            {/*        {error && <Alert variant="danger">{error}</Alert>}*/}
            {/*        <Form onSubmit={handleSubmit}>*/}
            {/*        <Form.Group id="email">*/}
            {/*            <Form.Label>Email</Form.Label>*/}
            {/*            <Form.Control type="text" ref={emailRef} required/>*/}
            {/*        </Form.Group>*/}
            {/*            /!*<Form.Group id="password">*!/*/}
            {/*            /!*    <Form.Label>Password</Form.Label>*!/*/}
            {/*            /!*    <Form.Control type="password" ref={passwordRef} required/>*!/*/}
            {/*            /!*</Form.Group>*!/*/}
            {/*            /!*<Form.Group id="passwordConfirm">*!/*/}
            {/*            /!*    <Form.Label>Confirm Password</Form.Label>*!/*/}
            {/*            /!*    <Form.Control type="password" ref={passwordConfirmRef} required/>*!/*/}
            {/*            /!*</Form.Group>*!/*/}
            {/*            <Button disabled={loading} className="w-100 mt-3"  type="submit">Add User</Button>*/}
            {/*        </Form>*/}
            {/*    </Card.Body>*/}
            {/*</Card>*/}

        </>
    )
}

export default AddUserToReport