import React, {useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import PacerMenu from "../../Menu";
import AdminMenu from "./AdminMenu/AdminMenu";
import {Outlet} from "react-router";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import AddClient from "./AddClient";
import EditClient from "./EditClient";
import {Route, Routes} from "react-router-dom";
import AddPacerUser from "./AddPacerUser";
import EditPacerUser from "./EditPacerUser";

const theme = createTheme();

const Admin = () => {


    const AdminPages = () => {
        return (
            <Outlet className={"detail"} />
        )
    }
    return (

        <ThemeProvider theme={theme}>
            <PacerMenu/>
           <AdminMenu/>

            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                    <Routes>
                        <Route path="/" element={<AdminPages />}>
                            <Route path="adduser" element={<AddUser/>}/>
                            <Route path="addpaceruser" element={<AddPacerUser/>}/>
                            <Route path="edituser" element={<EditUser/>} />
                            <Route path="editpaceruser" element={<EditPacerUser/>} />
                            <Route path="addclient" element={<AddClient/>} />
                            <Route path="editclient" element={<EditClient/>} />
                        </Route>
                    </Routes>
                <AdminPages/>
            </Container>
        </ThemeProvider>
    )
}

export default Admin