import {Row, Container, Image} from "react-bootstrap";
import devilsTower from "../../../assets/devilstower.jpeg";
import PacerMenu from "../../Menu";
import {useEffect} from "react";
const Resources = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
        <Row style={{ maxWidth: "80%", margin: "0 auto" }}>
            <Image src={devilsTower}></Image>
        </Row>
        </Container>
    )
}
export default Resources;