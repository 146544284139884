import React from "react";
import {Col, Row} from "react-bootstrap";
import {Book} from "react-bootstrap-icons";
import {ExcelDateToJSDate} from "../../utils/functions";

export const AbstractTableHeaders =
        <thead>
        <tr>
            <th>View</th>
            <th>County</th>
            <th>Instrument</th>
            <th>Effective Date</th>
            <th>Filed Date</th>
            <th>Received Date</th>
            <th>Grantor</th>
            <th>Grantee</th>
            <th>Book-Page</th>
            <th>Document Number</th>
            <th>Description</th>
            <th>WOL</th>
            <th>Comments</th>
        </tr>
        </thead>
;

    export const AbTable = (props) => (
        props.documentList !== undefined ?
        props.documentList.map((document, index) => {
    return (<tr key={index} >
        <td>
            <Row>
                <Col>
                    <Book onClick={() => {
                        window.open(`https://storage.googleapis.com/documentdatabase-d2450.appspot.com/${document.county}/${document.documentNumber}.pdf`)
                    }}
                    />

                </Col>
            </Row>
        </td>
        <td>{document.county}</td>
        <td>{document.instrument}</td>
        <td>{ExcelDateToJSDate(document.effectiveDate)}</td>
        <td>{ExcelDateToJSDate(document.fileDate)}</td>
        <td>{ExcelDateToJSDate(document.receivedDate)}</td>
        <td>{document.grantor}</td>
        <td>{document.grantee}</td>
        <td>{document.book}-{document.page}</td>
        <td>{document.documentNumber}</td>
        <td>{document.description}</td>
        <td>{document.wol}</td>
        <td>{document.comments}</td>
    </tr>)
}
)
            : <></>
);