import {Form} from "react-bootstrap";
import React, {useEffect} from "react";
import {queryForClientData} from "../../../../utils/queries/QueriesForUsersAndClients";


const SelectClient = (props) => {


    useEffect(() => {
        queryForClientData(props.setClientList)
    }, []);
    console.log(props.clientList)
    return (
        <>
            <Form>
                <Form.Select
                    name={"client"}
                    onChange={props.onChange}
                >
                    {props.clientList.map((client, index) => {
                        if (client.name !== "Pacer")
                        return (
                            <option key={index} value={client.id}>{client.name}</option>)
                    })
                    }
                </Form.Select>
            </Form>


        </>
    )
}


export default SelectClient;