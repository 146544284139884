import { Container} from "react-bootstrap";
import PacerMenu from "../Menu";
const Blog = () => {
    return (
        <Container fluid>
            <PacerMenu/>
        Blog
        </Container>
    )
}
export default Blog;