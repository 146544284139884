import React, {useEffect, useState} from "react";
import {Table, Row, Col, Container} from "react-bootstrap";

import AbstractHeader from "./AbstractHeader";
import {AbstractTableHeaders, AbTable} from "./AbstractTable";

import {AddEditDocModal} from "../../components/modals/AddEditDocModal";
import {importAbstractModal} from "./modals/ImportAbstractModal";
import PacerMenu from "../Menu";
import {queryForClientData} from "../../utils/queries/QueriesForUsersAndClients";

const AbstractTable = () => {
    const [document, setDocument] = useState([]);
    const [docQueryResults, setDocQueryResults] = useState([]);
    const [showEditHeader, setShowEditHeader] = useState(false);
    const [showCreateClientModal, setShowCreateClientModal] = useState(false);
    const [clientList, setClientList] = useState();
    const [client, setClient] = useState();
    const [reportList, setReportList] = useState();
    const [report, setReport] = useState();
    const [docURL, setDocURL] = useState("")
    const [updated, setUpdated] = useState(false)
    const [limited, setLimited] = useState(false)


    const handleCloseEditHeader = () => {
        setShowEditHeader(false);
    }
    const handleShowEditHeader = () => {
        setShowEditHeader(true);
    }

    const [abstractFiles, setAbstractFiles] = useState([]);

    //Edit Modal *******

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    ///    ********

    useEffect(() => {
        setDocQueryResults([])
    }, []);

    useEffect(() => {
        queryForClientData(setClientList)
    }, []);

    return (
        <Container fluid>
            <PacerMenu/>
            <Row>
                <Col>
                </Col>
                <Col xs={10}></Col>
            </Row>
            <AbstractHeader
                handleShow={handleShowEditHeader}
                documentList={docQueryResults}
                abstractFiles={abstractFiles}
                showCreateClientModal={showCreateClientModal}
                setShowCreateClientModal={setShowCreateClientModal}
                setAbstractFiles={setAbstractFiles}
                limited={limited}
                updated={updated}
            />

            <Table striped bordered hover>
                {AbstractTableHeaders}
                <tbody>
                <AbTable
                    handleShow={handleShow}
                    setDocument={setDocument}
                    docQueryResults={docQueryResults}
                />
                </tbody>
            </Table>

            {importAbstractModal(
                showEditHeader,
                handleCloseEditHeader,
                docQueryResults,
                setDocQueryResults,
                abstractFiles,
                setAbstractFiles,
                setClient,
                reportList,
                setReport,
                report,
                docURL,
                setDocURL,
                limited,
                setLimited,
                updated,
                setUpdated

            )}

            {AddEditDocModal(show, handleClose, document)}
        </Container>
    )
}

export default AbstractTable;