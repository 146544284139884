import React, {useRef, useState}  from "react";
import {useAuth} from '../contexts/AuthContext'
import {Card, Form, Button, Container, Alert, Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PacerMenu from "./Menu";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../services/firebase_config/firebase";
import {queryDatabaseForClientName} from "../utils/queries/QueriesForUsersAndClients";


const Pacer_Login = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, currentUser} = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

//TODO: Update to production Pacer ID prior to deploy:
    const PACER_ID = "o6T5E8Eq2MWhP8GKrY08"

    async function handleSubmit(e) {

        e.preventDefault()
        setError("login pressed")
        try {
            setError("")
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
        } catch {
            setError("Failed to log in")
        }
        setLoading(false)
        const uid = localStorage.getItem("user")
        const docRef = doc(db, "userData", uid);

        await getDoc(docRef).then((doc) => {
            //adds clientname to local storage:
           queryDatabaseForClientName(doc.data().clientId, false)
            //adds userdata...
            localStorage.setItem("userData", JSON.stringify({
                admin: doc.data().permissions.canAddUsers,
                clientReports: doc.data().permissions.clientReports,
                clientId: doc.data().clientId,
                isClient: doc.data().clientId !== PACER_ID ? true : false,
                name: doc.data().firstName + " " + doc.data().lastName,
            }))
            doc.data().clientId === PACER_ID ? navigate("/abstract") : navigate("/clienthome")
        })
    }

    return (
        <Container fluid>
            <Row>
                <PacerMenu/>
            </Row>
            <Row>
                <Col></Col>
                <Col xs={4}>
            <Card className="w-100" style={{ maxWidth: "400px" }}>
                <Card.Body>
                    <h2 className="text-center mb-4" >Pacer Portal</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" ref={emailRef} required/>
                    </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required/>
                        </Form.Group>
                    <Button className="w-100 mt-3"  type="submit">Login</Button>
                    </Form>
                </Card.Body>
            </Card>
                    </Col>
                <Col></Col>
                </Row>
            </Container>
    )
}

export default Pacer_Login