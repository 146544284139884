import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import AlertBox from "../../../components/Alerts/AlertBox";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import AdminMenu from "./AdminMenu/AdminMenu";
import {addDoc, collection, doc, setDoc} from "firebase/firestore";
import {db} from "../../../services/firebase_config/firebase";
import {useNavigate} from "react-router-dom";
import {addClient} from "../../../utils/functions";

const AddClient = () => {
const navigate = useNavigate();
    const [clientName, setClientName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [alert, setAlert] = useState({message: "", severity: "success"});

    const theme = createTheme();
//     const handleSubmit = async (e)  => {
//         e.preventDefault()
//         try {
//             const clientData = {
//                     email: email,
//                     name: clientName,
//                     address: address,
//                     phone: phone
//                 };
//
// const collectionRef = collection(db, "clientData");
//             addDoc(collectionRef, clientData).then(() => {
//                 return null
//             });
//             navigate("/admin/addclient")
//
//         } catch {
//             // setAlert({severity: "error", message: "Failed"})
//         }
//     }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
        <Box component="form"
         onSubmit={(e) => {
             addClient(e, clientName, address, phone)
             navigate("/admin/addclient")}
        }
         sx={{
             display: 'flex',
             flexDirection: 'column',
             alignItems: 'center',
         }}
    >
        <Grid container spacing={2}>
            <Typography>Add Client</Typography>
            <Grid item xs={12}>
                <TextField
                    label={"Client Name"}
                    required
                    fullWidth
                    onChange={(e) => setClientName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={"Address"}
                    required
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={"Phone"}
                    required
                    fullWidth
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Grid>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Add Client
            </Button>
        </Grid>
    </Box>
            </Container>
        </ThemeProvider>
    )
}

export default AddClient;