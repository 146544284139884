import {Card, Row, Container, Image, Col} from "react-bootstrap";
import Windmill from "../../../assets/Windmill.jpeg";
import Pacer_Menu from "../../Menu";
import {useEffect} from "react";

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Pacer_Menu/>
        <Card style={{ maxWidth: "80%", margin: "0 auto" }}>
            <Row>
                <Image src={Windmill}></Image>
            </Row>
        </Card>
            <Card style={{ maxWidth: "80%", margin:  "0 auto" }}>
            <Card.Body>
                <Card.Title className="text-center">Contact Us</Card.Title>
                <Col className="text-center px-md-3">
                    <a href="mailto:pacerpartners@pacerenergy.com">pacerpartners@pacerenergy.com</a>
                </Col>
                    <Col className="text-center px-md-3">307-685-3700</Col>
                <Row>
                </Row>
                <Row>
                    <Col className="text-center px-md-3">Gillette Office</Col>
                    <Col className="text-center px-md-3">Cheyenne Office</Col>
                </Row>

                <Row>
                    <Col className="text-center px-md-3">310 S Miller Ave Suite A.</Col>
                    <Col className="text-center px-md-3">1603 Capitol Ave. Suite 209</Col>
                </Row>
                <Row>
                    <Col className="text-center px-md-3">Gillette, Wyoming 82716, US</Col>
                    <Col className="text-center px-md-3">Cheyenne, Wyoming 82001, US</Col>
                </Row>
            </Card.Body>
        </Card>
        </Container>
    )
}
export default ContactUs;