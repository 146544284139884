import {Table, Container, Row, ProgressBar, Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {ClientReportTable, ClientReportTableHeaders} from "./ClientReportTable";
import PacerMenu from "../../Menu";
import {
 getClientReportsFromClientName
} from "../../../utils/queries/QueriesForUsersAndClients";

const ClientHome = () => {
    const [clientReportList, setClientReportList] = useState(undefined);
    const [report, setReport] = useState([]);
    const { currentUser } = useAuth()
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState({message:"", percentage:0})
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (currentUser !== undefined) {
                getClientReportsFromClientName(setClientReportList)
        }
    }, [currentUser]);
    console.log(clientReportList)
    return (
        <Container fluid>
            <Row>
            <PacerMenu/>
            </Row>
            {clientReportList !== undefined ? clientReportList[0].project === "no results found" ? <>No Reports Found</> :
                <Table className="mt-3" striped bordered hover>
                    {ClientReportTableHeaders (clientReportList[1].client)}
                    <tbody>
                    <ClientReportTable reports={clientReportList} show={show} handleShow={handleShow} handleClose={handleClose} report={report} setReport={setReport} setLoading={setLoading} />
                    </tbody>

                </Table> : <>No Reports Found</>
            }
            {loading.message && <Alert variant="info">{loading.message}</Alert>}
            {loading.message && <ProgressBar now={loading.percentage}/>}

        </Container>
    )
}


export default ClientHome;