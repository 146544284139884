import {Card, Container, Row} from "react-bootstrap";
import PacerMenu from "../../Menu";
import document from "../../../assets/document.jpg"
import {useEffect} from "react";
const DigitalImaging = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
            <Row className="justify-content-center">
            <Card className="col-8 col-md-8">
            <Card.Body>
                <div
                    style={{
                        backgroundImage: `url(${document})`,
                        backgroundPosition: 'center bottom',
                        backgroundSize: 'cover',
                        height: '400px',
                        width: '100%'
                    }}
                />
                <Card.Text>
                    Pacer Energy is pleased to provide digital imaging services throughout the rocky mountain region at
                    a discounted day rate. We provide digital imaging of all Federal, State and County level public
                    records. Our three strategic locations across Wyoming drastically reduce the often burdensome costs
                    typically associated with imaging. Federal and State Oil & Gas Lease files, as well as Federal Unit
                    and Communitization Agreements can be accessed and digitized quickly and cost-effectively.

                    We also offer digital archiving and database management services to provide our clients convenient
                    access to their ownership reports, county records, and more.
                </Card.Text>
            </Card.Body>
        </Card>
            </Row>
        </Container>
    )
}
export default DigitalImaging;