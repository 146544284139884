import {Alert, Form, ProgressBar, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {ReportTable, ReportTableHeaders} from "./tables/ReportTable";
import Pacer_Menu from "../pages/Menu";
import {getClientReports, queryForClientData} from "../utils/queries/QueriesForUsersAndClients";

const SelectClient = () => {
    const [clientReportList, setClientReportList] = useState(undefined);
    const [clientList, setClientList] = useState([])
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState({message:"", percentage:0})
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const onChange = (e) => {
        if (e.target.value !== "Select Client")
            getClientReports(e.target.value, setClientReportList).then(r => {})
    }
    useEffect(() => {
        queryForClientData(setClientList)
    }, []);

    return (
        <>
            <Pacer_Menu/>
            <Form>
                <Form.Select
                    name={"client"}
                    onChange={onChange}
                >
                    {clientList.map((client, index) => {
                        if (client.name !== "Pacer")
                        return (
                            <option key={index}>{client.name}</option>)
                    })
                    }
                </Form.Select>
            </Form>
            {clientReportList === undefined ? <></> :
                <Table className="mt-3" striped bordered hover>
                    {ReportTableHeaders}
                    <tbody>
                    <ReportTable
                        reports={clientReportList}
                        show={show}
                        handleShow={handleShow}
                        handleClose={handleClose}
                        report={report}
                        setReport={setReport}
                        setLoading={setLoading}
                        setClientReportList={setClientReportList}
                    />
                    </tbody>
                </Table>
            }
            {loading.message && <Alert variant="info">{loading.message}</Alert>}
            {loading.message && <ProgressBar now={loading.percentage}/>}

        </>
    )
}


export default SelectClient;