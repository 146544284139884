import {Card, Container, Row} from "react-bootstrap";
import DroneServicesImage from "../../../assets/DroneServices.JPG";
import PacerMenu from "../../Menu";
import {useEffect} from "react";
const DroneServices = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <PacerMenu/>
            <Row className="justify-content-center">
                <Card className="col-8 col-md-8">
                    <div
                        style={{
                            backgroundImage: `url(${DroneServicesImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: '400px',
                            width: '100%'
                        }}
                    />
                    <Card.Body>
                        <Card.Title>Aerial Imaging and Drone Services</Card.Title>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}
export default DroneServices;