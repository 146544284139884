import React from 'react';
import {Card, Button, ListGroup} from "react-bootstrap"
import {exportFile} from "../../../features/Excel/exports/exportWithLinks";

const ClientAbstractHeader =(props) => {
    return (
            <Card >
                <Card.Header> {props.reportTitle}: {props.headerDetails.client ?? ""}</Card.Header>
                <Card.Body>
                    <Card.Title>Pertaining to:</Card.Title>
                    <ListGroup >
                        <ListGroup.Item>Tract 1: {props.headerDetails.townshipAndRange}</ListGroup.Item>
                        <ListGroup.Item>Section {props.headerDetails.section}</ListGroup.Item>
                        <ListGroup.Item>County/State: {props.headerDetails.county} </ListGroup.Item>
                        <ListGroup.Item>Containing approximately {props.headerDetails.acreage} acres, more or less
                        </ListGroup.Item>
                        <ListGroup.Item> From: {props.headerDetails.date}</ListGroup.Item>
                        <ListGroup.Item> Last Page: {props.headerDetails.lastPage}</ListGroup.Item>
                    </ListGroup>
                    {/*<ListGroup >*/}
                    {/*    <ListGroup.Item>MTPS PLat: {props.headerDetails.plat}</ListGroup.Item>*/}
                    {/*    <ListGroup.Item>Federal Lease File: {props.headerDetails.fedLease} link</ListGroup.Item>*/}
                    {/*    <ListGroup.Item>Serial Register Page: {props.headerDetails.srp}</ListGroup.Item>*/}
                    {/*    <ListGroup.Item>Net Revenue Report for Subject Lands: {props.headerDetails.report}</ListGroup.Item>*/}
                    {/*    <ListGroup.Item>Index for Subject Lands: {props.headerDetails.index}</ListGroup.Item>*/}
                    {/*</ListGroup>*/}
                    {/*<Card.Text>*/}
                    {/*    Federal Oil and Gas Lease File for {props.headerDetails.fedLease} from Inception of Lease until June 12, 2018 at 5:00 P.M.*/}
                    {/*    County Documents from Inception of Lease until {props.headerDetails.certDate} at 5:00 P.M.*/}
                    {/*</Card.Text>*/}
                    <Button  className="m-3" variant="secondary" onClick={() => exportFile({header: [props.headerDetails], list: props.documentList, files: props.abstractFiles, reportTitle: props.reportTitle})}>Download Abstract</Button>
                </Card.Body>
                <Card.Footer className="text-muted">Prepared by Pacer Energy, LLC</Card.Footer>
            </Card>
    )
}

export default ClientAbstractHeader