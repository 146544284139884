import {createContext, useContext, useEffect, useState} from 'react';
import {auth, db} from '../services/firebase_config/firebase';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut
} from "firebase/auth";
import {doc, setDoc} from "firebase/firestore";

const AuthContext = createContext();


export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState("none");

    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password).then((user) => {
            localStorage.setItem("user", user.user.uid)
        })
    };
    const logout = () => {
        signOut(auth)
        localStorage.removeItem('user')
        localStorage.removeItem('userData')
        localStorage.removeItem('clientName')

    };

    const createUser = (email , password, firstName, lastName,canAddUsers, canCreateClientReports, clientId) => {

        return createUserWithEmailAndPassword(auth, email, password).then((user) => {
            //TODo: use "User.getToken()" instead of user.uid
            setDoc(doc(db, "userData", user.user.uid), {
                email: email,
                firstName: firstName,
                lastName: lastName,
                permissions: {canAddUsers: canAddUsers, clientReports: canCreateClientReports},
                clientId: clientId,
            });
            resetUser(email);
        })
    };

    const resetUser = (email) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                console.log("Password reset email sent!")
                // ..
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
        })
        return () => {
            unsubscribe();
        }

    }, []);

    const value = {
        login,
        logout,
        createUser,
        currentUser
    };

    return (
        <>
            <AuthContext.Provider value ={value}>
                {children}
            </AuthContext.Provider>
        </>
    );
}

